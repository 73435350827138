<template>
  <v-container class="donate-points"
    ><h4 class="text-uppercase mb-1">
      {{ $t("donatePoints.title") }}
    </h4>
    <div class="text-body-0">
      {{ $t("donatePoints.subtitle") }}
    </div>
    <h4 class="text-uppercase mb-1">
      {{ $t("donatePoints.form") }}
    </h4>
    <div class="text-body-0">
      {{ $t("donatePoints.formDesc") }}
    </div>
    <v-form ref="form" @submit.prevent="saveData"
      ><div class="mt-3">
        <v-text-field
          color="primary"
          v-model="userData.toFidelityCard"
          :label="$t('donatePoints.receiverCard') + ' *'"
          dense
          outlined
          rounded
          :rules="[requiredValue()]"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          color="primary"
          v-model="receiverCardConfirm"
          :label="$t('donatePoints.confirmReceiverCard') + ' *'"
          dense
          outlined
          rounded
          :rules="cardConfirmRules"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          color="primary"
          v-model="userData.points"
          :label="$t('donatePoints.pointsAmount')"
          :hint="hint"
          persistent-hint
          dense
          outlined
          rounded
          :rules="pointsRule"
        ></v-text-field>
      </div>
      <!-- <div class="mt-2">
        <v-text-field
          color="primary"
          v-model="userData.name"
          :label="$t('donatePoints.name')"
          dense
          outlined
          rounded
          :rules="[requiredValue()]"
        ></v-text-field>
      </div> -->
      <div class="py-3" v-if="responseMessage != ''">
        <ResponseMessage class="mt-3" :response="responseMessage" />
      </div>
      <div>
        <v-btn
          :loading="loading"
          type="submit"
          color="primary"
          large
          block
          depressed
          rounded
        >
          {{ $t("donatePoints.send") }}
        </v-btn>
      </div></v-form
    >
  </v-container>
</template>
<style lang="scss">
.donate-points {
  .v-messages {
    color: $primary;
    font-size: 14px;
  }
}
</style>
<script>
import { requiredValue, isNumber } from "~/validator/validationRules";
import { mapGetters, mapActions } from "vuex";
import AbbondanzaRegistrationService from "@/service/abbondanzaRegistrationService";
import ResponseMessage from "@/components/ResponseMessage.vue";

export default {
  name: "DonatePoints",
  components: { ResponseMessage },
  data() {
    return {
      loading: false,
      requiredValue: requiredValue,
      pointsRule: [isNumber(), requiredValue()],
      cardConfirmRules: [
        v => !!v || "Confermare la carta",
        v => v === this.userData.toFidelityCard || "Le carta non coincidono"
      ],
      userData: {},
      receiverCardConfirm: null,
      responseMessage: ""
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser",
      userPoints: "custom/userPoints"
    }),
    hint() {
      return "I tuoi punti disponibili sono: " + this.userPoints;
    }
  },
  methods: {
    ...mapActions({
      updateUserPoints: "custom/updateUserPoints"
    }),
    saveData() {
      if (this.$refs.form.validate()) {
        this.responseMessage = "";
        this.loading = true;
        AbbondanzaRegistrationService.transferPoints(this.userData)
          .then(data => {
            this.responseMessage = data.response;
          })
          .finally(() => {
            this.updateUserPoints();
            this.loading = false;
            this.userData.points = 0;
          });
      }
    }
  },
  created() {
    this.userData.name = this.user.firstName;
  }
};
</script>
